
/*
 * Config constants.
 */

const PROD = {
	API: "https://api-27.herokuapp.com/api",
}

const DEV = {
	API: "/api",
}

//export const CONFIG = process.env.NODE_ENV === "production" ? PROD : DEV;
export const CONFIG = PROD;

